/*
	@description This function is used to wait for an element to be rendered in the DOM.
	@param {string} selector - The selector of the element to wait for.
	@param {number} timeout optional - The time in ms to wait for the element to be rendered in the DOM.
	@return Promise<any> - The promise that resolves when the element is rendered in the DOM.
 */
function waitForElm(selector: string, timeout?: number): Promise<any> {
	return new Promise((resolve, reject) => {
		if (timeout) {
			const timer = setTimeout(() => {
				reject()
			}, timeout)
		}

		if (document.querySelector(selector)) {
			return resolve(document.querySelector(selector))
		}

		const observer = new MutationObserver(mutations => {
			if (document.querySelector(selector)) {
				observer.disconnect()
				resolve(document.querySelector(selector))
			}
		})

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		})
	})
}

export default waitForElm
